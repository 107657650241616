jQuery(function ($) {
  $("#load-more").click(function () {
    const button = $(this);
    const data = {
      action: "loadmore",
      query: loadmore_params.posts,
      page: loadmore_params.current_page,
    };

    $.ajax({
      url: loadmore_params.ajaxurl,
      data: data,
      type: "POST",
      beforeSend: function (xhr) {
        button.text("Laden...");
      },
      success: function (data) {
        if (data) {
          button.text("Meer laden");
          $(".calendar-item").last().after(data);

          loadmore_params.current_page++;
          if (loadmore_params.current_page == loadmore_params.max_page) {
            button.parent().remove();
          }
        } else {
          button.remove();
        }
      },
    });
  });

  $(document).ready(function () {
    function loopKeywords() {
      const $keywords = $(".keyword");
      let currentIndex = 0;

      function adjustKeywordContainerWidth() {
        let maxWidth = 0;
        $keywords.each(function () {
          maxWidth = Math.max(maxWidth, $(this).width());
        });

        $(".keyword-container").width(maxWidth);
      }

      adjustKeywordContainerWidth();
      $(window).resize(adjustKeywordContainerWidth);

      function showNextKeyword() {
        const $currentKeyword = $keywords.eq(currentIndex);
        const $nextKeyword = $keywords.eq(
          (currentIndex + 1) % $keywords.length
        );

        $currentKeyword.animate({ top: "-100%" }, 1000, function () {
          $currentKeyword.css({ top: "100%" });
        });
        $nextKeyword.animate({ top: "0%" }, 1000);

        currentIndex = (currentIndex + 1) % $keywords.length;
      }

      $keywords.eq(currentIndex).css({ top: "0%" });
      setInterval(showNextKeyword, 2500);
    }

    loopKeywords();

    let lastScrollTop = 0;
    const $navbar = $(".navbar-nav");
    const navbarTop = $navbar.offset().top + $navbar.height();

    $(window).scroll(function () {
      if ($(window).width() > 640) {
        const scrollTop = $(this).scrollTop();

        const isScrollingDown =
          scrollTop > lastScrollTop && scrollTop > navbarTop;
        $navbar.toggleClass("navbar-nav--pinned", !isScrollingDown);
        $navbar.toggleClass("navbar-nav--unpinned", isScrollingDown);

        lastScrollTop = scrollTop;
      }
    });

    let isOpen = false;
    const $menuIcon = $(".menu-icon");

    $menuIcon.on("click", function () {
      isOpen = !isOpen;
      if (isOpen) {
        $(".navbar-nav").fadeIn();
        $menuIcon.addClass("menu-icon--close");
      } else {
        $(".navbar-nav").fadeOut();
        $menuIcon.removeClass("menu-icon--close");
      }
    });

    $(".carousel").each(function () {
      const type = $(this).data("type");
      const options = {
        dragFree: type === "timeline",
      };
      const emblaApi = EmblaCarousel(this, options, [
        EmblaCarouselClassNames(),
      ]);

      const $wrapper = $(this).parent();
      const $prevButton = $wrapper.find(".carousel__prev");
      const $nextButton = $wrapper.find(".carousel__next");

      $prevButton.on("click", () => emblaApi.scrollPrev());
      $nextButton.on("click", () => emblaApi.scrollNext());

      function adjustContainerLayout() {
        const $containerNode = $(emblaApi.containerNode());
        const containerWidth = $containerNode.outerWidth();
        const $items = $containerNode.find(".carousel__slide");

        let itemsWidth = 0;
        $items.each(function () {
          itemsWidth += $(this).outerWidth(true);
        });

        if (itemsWidth <= containerWidth) {
          $containerNode.css("justify-content", "center");
          $prevButton.hide();
          $nextButton.hide();
          emblaApi.reInit({
            watchDrag: false,
          });
        } else {
          $containerNode.css("justify-content", "flex-start");
          $prevButton.show();
          $nextButton.show();
          emblaApi.reInit({
            watchDrag: true,
          });
        }
      }

      function toggleButtonState() {
        if (emblaApi.canScrollPrev()) {
          $prevButton.removeAttr("disabled");
        } else {
          $prevButton.attr("disabled", "disabled");
        }
        if (emblaApi.canScrollNext()) {
          $nextButton.removeAttr("disabled");
        } else {
          $nextButton.attr("disabled", "disabled");
        }
      }

      emblaApi
        .on("init", () => {
          toggleButtonState();
          adjustContainerLayout();
        })
        .on("reInit", () => toggleButtonState())
        .on("select", () => toggleButtonState())
        .on("resize", () => adjustContainerLayout());
    });
  });

  function handleContentBlockClick() {
    if ($(window).width() <= 640) {
      $(".content-block-item")
        .off("click")
        .on("click", function () {
          $(".content-block-item")
            .not(this)
            .find(".content-block-item__body")
            .slideUp();
          $(".content-block-item").not(this).find(".bubble-image-sm").slideUp();

          $(this).find(".content-block-item__body").slideDown();
          $(this).find(".bubble-image-sm").slideDown();
        });
    } else {
      $(".content-block-item").off("click");
    }
  }

  handleContentBlockClick();
  $(window).resize(handleContentBlockClick);

  $(".wpcf7")
    .find("textarea")
    .each(function () {
      this.style.height = `${this.scrollHeight}px`;
    })
    .on("input", function () {
      this.style.height = 0;
      this.style.height = `${this.scrollHeight}px`;
    });
});
